/* eslint-disable react/prop-types */

import React from 'react';
import AccessDeined1 from '@dolstaff/webcomponents/es/AccessDenied/AccessDenied1';
import TopSideMenu from './TopSideMenu';

const OnlyStudentCanView = ({ userInfo }) => {
  return (
    <TopSideMenu user={userInfo}>
      <div style={{ paddingBottom: '60px' }}>
        <AccessDeined1 />
      </div>
    </TopSideMenu>
  );
};

export default OnlyStudentCanView;
