import React from 'react';
import { navigate } from 'gatsby';

import { MainLayout } from '../components/Layout';
import SEO from '../components/SEO';
import { isStudent } from '../utils/auth';

const browser = typeof window !== 'undefined' && window;

const NotFoundPage = () => {
  React.useEffect(() => {
    if (isStudent() && browser) {
      window.location.href = '/app/lms/my-courses';
      return;
    }
    navigate('/app');
  }, []);
  return (
    browser && (
      <MainLayout>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </MainLayout>
    )
  );
};
export default NotFoundPage;
