import React, { useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import LoadingPanel from '@dolstaff/shared/es/LoadingPanel';
import * as Auth from '../utils/auth';
import { AuthLayout } from './Layout';
import Icon from './Icon';
import { getRoles } from '../utils/tokenUtils';
import OnlyStudentCanView from './OnlyStudentCanView';

/**
 * @return {null}
 */
function YourEcosystems() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [roles, setRoles] = React.useState();
  const [userInfo, setUserInfo] = React.useState();
  const [isLogged, setLogged] = React.useState(false);

  useEffect(() => {
    Auth.isLogged().then(isLoggedIn => {
      if (isLoggedIn) {
        Auth.getUserInfo().then(userInfoData => setUserInfo(userInfoData));
        Auth.getAccessToken().then(userRoles => setRoles(getRoles(userRoles)));
        if (Auth.isStudent() && window) {
          window.location.href = '/app/lms/my-courses';
        }
      } else {
        navigate('/login');
        return;
      }
      setLogged(isLoggedIn);
    });
  }, []);

  useEffect(() => {
    if (isLogged && userInfo && roles) {
      setIsLoading(false);
    }
  }, [isLogged, userInfo, roles]);

  if (isLoading) {
    return <LoadingPanel />;
  }

  // check roles , DT-2890
  if (!(Array.isArray(roles) && roles.length > 0)) {
    return <OnlyStudentCanView userInfo={userInfo} />;
  }

  return (
    <AuthLayout user={userInfo}>
      <MainContainer>
        <Title>Your apps</Title>
        <SubTitle>By signing up, you can start trying all of our apps</SubTitle>
          <AppList>
            <AppItem
              onClick={() => {
                window.location.href = '/app/lms';
              }}
            >
              <AppInfoWrapper>
                <Name>Learning Management System</Name>
                <NameMobile>LMS</NameMobile>
              </AppInfoWrapper>
              <AppLink href="/app/lms">
                <TextHideOnMobile>Go to webapp</TextHideOnMobile> <RightArrow />
              </AppLink>
            </AppItem>
          </AppList>
      </MainContainer>
    </AuthLayout>
  );
}

export default YourEcosystems;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 980px;

  @media (max-width: 996px) {
    max-width: calc(100% - 40px);
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0 0 30px 0;
  padding: 0;
  @media (max-width: 524px) {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;
const SubTitle = styled.h2`
  font-size: 1.2rem;
  margin: 0 0 30px 0;
  padding: 0;
  color: #a8a8a8;
  @media (max-width: 524px) {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
`;
// const Footer = styled.footer`
//   font-size: 1.125rem;
//   padding: 80px 0;

//   @media (max-width: 524px) {
//     padding: 20px 0;
//     text-align: center;
//   }
// `;

// const PricingLink = styled(Link)`
//   text-decoration: none;
//   margin-left: 10px;

//   @media (max-width: 524px) {
//     display: block;
//     margin-top: 15px;
//   }
// `;

const RightArrow = styled(Icon).attrs({ type: 'RightArrow' })`
  font-size: 0.75rem;
  margin-left: 2px;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  min-width: 300px;
  display: block;
  @media (max-width: 670px) {
    min-width: 0;
    display: none;
  }
`;
const NameMobile = styled.div`
  font-weight: bold;
  min-width: 300px;
  display: none;
  @media (max-width: 670px) {
    display: block;
    min-width: 0;
    display: block;
    font-size: 16px;
  }
`;

const AppLink = styled.a`
  text-decoration: none;
  color: #000000;
  display: block;
  text-align: right;
`;
const AppItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
`;
const AppInfoWrapper = styled.div`
  display: flex;
  flex: 1;
`;
const AppList = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.25);

  ${AppItem} + ${AppItem} {
    border-top: 1px solid #f7f7f7;
  }

  margin: 20px 0;
`;

const TextHideOnMobile = styled.span`
  display: inline;
  @media (max-width: 524px) {
    display: none;
  }
`;
