import React from 'react';
import styled from 'styled-components';
import ImageLogo from './images/logo.png';

export const Container = styled.div`
  background-color: #f4f5f7;
  min-height: 100vh;

  .c-sticky {
    transition: all 0.3s ease;
    left: ${({ isOpen }) => (isOpen ? `248px` : `80px`)};
  }
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 14px 24px;
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(51, 70, 129, 0.1), inset 0px -1px 0px #e8ecef;
`;

export const Logo = styled(props => <img src={ImageLogo} {...props} alt="logo" />)`
  width: 28px;
  height: 32px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  margin-bottom: 0;

  &:hover {
    transform: scale(1.1);
  }
`;

export const TopBarLeft = styled.div`
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
export const TopBarRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const GreetingUser = styled.div`
  margin-right: 16px;
  cursor: pointer;
  .hello {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5e6c84;
  }
  .username {
    font-family: Muli;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2d3742;
  }

  &:hover {
    .username {
      transition: color 0.3s;
      color: #d14242;
    }
  }
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Body = styled.div`
  width: auto;
  padding-top: 120px;
  transition: all 0.3s ease;
`;

export const List = styled.div``;
