/* eslint-disable react/prop-types */
import React from 'react';
import Tooltip from '@dolstaff/shared/es/Tooltip';
import styled from 'styled-components';
import { Avatar, Body, Container, GreetingUser, Logo, TopBar, TopBarLeft, TopBarRight } from './index.css';
import { Dropdown } from '../Layout/AuthLayout/UserDropdown';

class TopSideMenu extends React.Component {
  static defaultProps = {
    user: {
      name: '',
      picture: '',
      email: '',
    },
    avatarUrl: '',
    isHideSideBar: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  render() {
    const { isMenuOpen } = this.state;
    const { user, avatarUrl, children, onAccountClick, onLogoClick } = this.props;

    return (
      <Container isOpen={isMenuOpen}>
        <TopBar>
          <TopBarLeft>
            <Logo onClick={onLogoClick} />
          </TopBarLeft>
          <TopBarRight>
            <GreetingUser onClick={onAccountClick}>
              <span className="hello">Hello,&nbsp;</span>
              <span className="username">{user.name || 'Anonymous'}</span>
            </GreetingUser>
            <Tooltip
              on="click"
              closeOnDocumentClick
              mouseLeaveDelay={300}
              mouseEnterDelay={0}
              trigger={() => (
                <Avatar>
                  <img src={avatarUrl || 'https://via.placeholder.com/40x40'} alt="avatar" />
                </Avatar>
              )}
              width="auto"
              position="bottom right"
              contentStyle={{
                padding: 0,
                boxShadow: 'none',
              }}
              arrow={false}
            >
              {() => (
                <DropdownWrapper>
                  <Dropdown user={user} />
                </DropdownWrapper>
              )}
            </Tooltip>
          </TopBarRight>
        </TopBar>
        <Body isMenuOpen={isMenuOpen}>{children}</Body>
      </Container>
    );
  }
}

export default TopSideMenu;

const DropdownWrapper = styled.div`
  > div {
    position: relative;
    right: 0;
    top: 0;
  }
`;
