import React from 'react';
import { Router } from '@reach/router';
import { initFirebase } from '@doltech/react-shared-components/es/utils/firebase';
import AuthVerify from '@doltech/react-shared-components/es/components/AuthVerify';
import firebase from 'firebase';
import LoadingPanel from '@dolstaff/shared/es/LoadingPanel';
import YourEcosystems from '../../components/YourEcosystems';
import Version from '../../components/Version';
import { AUTH_KEY } from '../../utils/auth';
import NotFoundPage from '../404';

const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    initFirebase(process.env.GATSBY_ENV === 'INT' ? 'development' : 'production', firebase)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <LoadingPanel />;
  }
  return (
    <div>
      <AuthVerify authKey={AUTH_KEY} />
      <Router>
        <YourEcosystems path="/app" />
        <NotFoundPage default />
      </Router>
      <Version />
    </div>
  );
};

export default App;
